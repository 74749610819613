import axios from 'axios';

 //prod
 export default axios.create({
  baseURL: "https://apisweepshondaacl.com/api",
  headers: {
    "Content-type": "application/json"  
  }
});  


/*  //local
 export default axios.create({
   baseURL: "http://localhost:8081/api",
   headers: {
     "Content-type": "application/json"  
   }
 })
 */