import React from 'react'
import HImg from "../assets/slowandlow header.jpg"

import "./styles.css"

const HeaderImg = () => {
  return (
    <div style={{background: 'white'}}>
      <img src={HImg} alt="Encabezado" className="header-page" />
    </div>
  )
}

export default HeaderImg