import React from "react";
import HeaderImg from "../../components/HeaderImg";
import ParticipantsDataService from "../../network/participants.service";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import "./stylesFinish.css";

const FinishPage = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [submitted, setSubmitted] = useState(false);


  const token = state ? state.token : null;
  const email = state ? state.email : null;

  const initialValues = {
    address: "",
    addres2:"",
    zip: "",
    state: "",
  };

  return (
    <div className="container" >
      <HeaderImg />
      <h1 className="text">Thanks! Your entry has been submitted.</h1>
      <h2 className="text-light">We will reach out to the winner on or around 11/22. Good luck!</h2>


   
    </div>
  );
};

export default FinishPage;
