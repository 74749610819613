import React from 'react'
import HeaderImg from "../../components/HeaderImg"
import RegisterForm from "../../components/register/RegisterForm"

import './stylesRegister.css';

const RegisterPage = () => {
  return (
    <div className="container" style={{ marginBottom: '5%' }}>
      <HeaderImg />
      <div className="container-title" >
        <a className="text-title">Slow & Low x Tea Leigh Sweepstakes</a>
      </div>
      <div className="container-copy">
       
          <a className="text-copy">The submission period has closed. A winner will be announced by 11/27. Good luck! </a>
       
       
     
      </div>
      <hr className="divider" />
    </div>
  )
}


/* const RegisterPage = () => {
  return (
    <div className="container" style={{ marginBottom: '5%' }}>
      <HeaderImg />
      <div className="container-title" >
        <a className="text-title">Slow & Low x Tea Leigh Sweepstakes</a>
      </div>
      <div className="container-copy">
        <div className="column">
          <a className="text-copy">Itching for a new tattoo? We've got you covered. Enter to win a personal hand poke tattoo session with Tea Leigh in Los Angeles! Winnings include travel, lodging, tattoo consultation, a full day session with Tea, plus a custom one of a kind Slow & Low themed T-Shirt designed by Tea themself! One winner will be chosen on 11/22. </a>
        </div>
        <div className="column">
          <a className="text-copy-title" >Grand Prize:</a>
          <br />
          <a className="text-copy2">$1,500 Travel Stipend
            <br />Tattoo consultation
            <br />Full-tattoo session (6 hours)
            <br />Custom S&L Shirt </a>
        </div>
      </div>
      <hr className="divider" />
      <RegisterForm />
    </div>
  )
} */

export default RegisterPage