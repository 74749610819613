import React from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import RegisterPage from "./pages/register/RegisterPage.jsx";
import FinishPage from "./pages/finish/FinishPage.jsx";
import "./pages/style.css";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/">
          <Route index element={<RegisterPage />} />
          <Route path="/finish" element={<FinishPage />} />
          <Route path="*" element={<Navigate to="/" replace={true} />} /> 
        </Route>
      </Routes>
    </div>
  );
}

export default App;
