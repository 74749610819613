import http from './http-common';

class ParticipantsDataService {
  create(data, headers) {
    return http.post("/participant", data, headers);
  }
  saveAddress(data){
    return http.post("/participant/address", data);
  }

}

export default new ParticipantsDataService();
